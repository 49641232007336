var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"rare-disease2-web@1.0.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// sentry.client.config.js

import * as Sentry from "@sentry/nextjs";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

if (publicRuntimeConfig.TARGET !== "start") {
  Sentry.init({
    dsn: "https://4bb45ba447705ffa9141155a26ede6b2@test-monitor.chard.org.cn/24",
    enabled: true,
    environment: publicRuntimeConfig.TARGET,
    maxBreadcrumbs: 50,
    sampleRate: 1.0,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay({ maskAllText: false })],
    release: publicRuntimeConfig.SENTRY_RELEASE,
  });
}
