import React from "react";
import { ConfigProvider } from "antd";
import theme from './themeConfig';
import zhCN from "antd/locale/zh_CN";

const withTheme = (node: JSX.Element) => (
    <ConfigProvider locale={zhCN} theme={theme}>
        {node}
    </ConfigProvider>
)

export default withTheme;